'use client'

import { useRef } from 'react'
import Link from 'next/link'
import { useRecoilState } from 'recoil'
import { mobileNavigation, messagesState } from '@atoms/index'
import Router from 'next/router'

import MobileMenu from '../mobile-menu'

import { useClickOutside } from '../../lib/hooks/useClickOutside.js'

export default function Navigation() {
    const [showMobileNavigation, setShowMobileNavigation] =
        useRecoilState(mobileNavigation)
    const [messages] = useRecoilState<any>(messagesState)

    const clickRef = useRef()

    const hasUnread =
        messages &&
        messages?.filter((thread: any) => {
            return thread.messages.some((x: any) => {
                return x.read_status === 0
            })
        })
    const unreadCount = hasUnread ? hasUnread.length : 0

    const onClickOutside = () => {
        setShowMobileNavigation(false)
    }
    useClickOutside(clickRef, onClickOutside)

    const navigation = [
        {
            name: 'Messages',
            href: '/messages',
            notification: unreadCount,
        },
        {
            name: 'Browse Members',
            href: '/members',
        },
        {
            name: 'Upgrade',
            href: '/upgrade',
        },
    ]

    return (
        // @ts-ignore
        <div ref={clickRef}>
            {unreadCount > 0 && (
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full top-1 left-1 md:left-auto md:-right-2 dark:border-gray-900">
                    {unreadCount}
                </div>
            )}
            <MobileMenu />
            <div
                className={
                    showMobileNavigation ? 'md:ml-6' : 'hidden md:block md:ml-6'
                }
            >
                <div className="absolute md:relative top-16 md:top-auto left-0 md:left-auto flex flex-col md:flex-row px-2 pt-2 pb-3 w-full bg-gray-900">
                    {navigation.map((link, i) => {
                        return (
                            <Link
                                href={link.href}
                                key={i}
                                onClick={() => setShowMobileNavigation(false)}
                                className="relative px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                            >
                                {link.name}
                                {link.notification > 0 && (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -left-2 md:left-auto md:-right-2 dark:border-gray-900">
                                        {link.notification}
                                    </div>
                                )}
                            </Link>
                        )
                    })}
                    {showMobileNavigation && (
                        <button
                            type="button"
                            onClick={() => {
                                setShowMobileNavigation(false)
                                Router.push('/upgrade')
                            }}
                            className="relative text-left md:ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                        >
                            Credits
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
